/*jslint browser: true, nomen: true */
/*global $ */
'use strict';

function PageTimer() {
  var self = this;
  this.getLoadTime = function () {
    var now = new Date().getTime();
    // Get the performance object
    window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
    var timing = performance.timing || {};

    var ms = now - timing.navigationStart;

    return Math.round(ms / 10) / 100;
  };

  this.logToConsole = function () {
    $(window).on('load', function () {
      console && console.info && console.info("Client loaded in " + self.getLoadTime() + 's');
    });
  };

  this.append = function (holder) {
    $(window).on('load', function () {
      holder.text(' | LT: ' + self.getLoadTime() + 's');
    });
  }
}


function StickyFooter($wrap, $footer) {
  var $window = $(window);
  this.updateWrapperCSS = function () {
    var footerHeight = $footer.outerHeight();

    $wrap.css({
      marginBottom: -1 * footerHeight,
      paddingBottom: footerHeight
    });
  };

  this.bindOnResize = function () {
    $window.on('resize', this.updateWrapperCSS);

    return this;
  };

  this.update = function () {
    this.updateWrapperCSS();

    return this;
  }
}


/*
 Ensure all external links load as new window/tabs:
 */

var App;

function ExternalLinkHandler() {
  var self = this;
  var hostname = document.location.hostname;

  this.matchInternalLink = [new RegExp("^https?:\/\/(.*?)" + hostname)];

  this.addTargetAttribute = function (context) {
    context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
      var anchor = $(this);
      var href = anchor.attr('href');
      var isInternalLink = false;

      for (var i = 0; i < self.matchInternalLink.length; i++) {
        var regex = self.matchInternalLink[i];
        if (href.match(regex)) {
          isInternalLink = true;
        }
      }

      if (!isInternalLink) {
        anchor.attr('target', '_blank').addClass('external-link');
      }
    });
  };

}

function UIBindings() {
  this.bindTooltips = function () {
    $('[data-toggle="tooltip"]').tooltip();
  };
  this.bindPopovers = function () {
    $('[data-toggle="popover"]').popover();
  };

  this.bindSlickCarousels = function () {
    $('[data-slick-carousel-logo]').slick({
      dots: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
    });

    $('[data-slick-carousel-default]').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true
    });

    $('[data-toggle="slick-nav"]').on('click', function (e) {
      e.preventDefault();
      var index = $(this).data('index');
      $('[data-slick-carousel-default]').slick('slickGoTo', index);
    });
  };

  // fulkcrum example
  this.closeModal = function(){
    $('.close').on('click',function(){
      console.log('hide');
      $('#home-modal').css('display','none');
      // $.cookie('modal', 'modal-seen');
      Cookies.set('modal', 'modal-seen');
    });
    $('.apply-btn').on('click',function(){
      console.log('hide');
      $('#home-modal').css('display','none');
      // $.cookie('modal', 'modal-seen');
      Cookies.set('modal', 'modal-seen');
    });
  }

  this.bindSharing = function () {
    $("[data-share-default]").each(function () {
      var shareHandler = new ShareHandler($(this));
      shareHandler.appendFacebook();
      shareHandler.appendTwitter();
      shareHandler.appendGoogle();
      //shareHandler.appendLinkedIn();
      //shareHandler.appendPinterest();
    });
  };

  this.bindMagnificpopup = function(){
    $('.gallery-image').magnificPopup({
         type: 'image',
         callbacks:
          {
              markupParse: function(template, values, item)
                  {
                      values.description = item.el.data('description');
                  }
          },
          gallery:{
            enabled:true
          },

          image:
              {
                  headerFit: true,
                  captionFit: true,
                  preserveHeaderAndCaptionWidth: false,
                  markup:
                  '<div class="mfp-figure">'+
                      '<figure>'+
                          '<header class="mfp-header">'+
                              '<div class="mfp-top-bar">'+
                                  '<div class="mfp-title"></div>'+
                                  '<div class="mfp-close"></div>'+
                                  '<div class="mfp-decoration"></div>'+
                              '</div>'+
                          '</header>'+
                          '<section class="mfp-content-container">'+
                              '<div class="mfp-img"></div>'+
                          '</section>'+
                          '<footer class="mfp-footer">'+
                              '<figcaption class="mfp-figcaption">'+
                                  '<div class="mfp-bottom-bar-container">'+
                                      '<div class="mfp-bottom-bar">'+
                                          '<div class="mfp-counter"></div>'+
                                          '<div class="mfp-description"></div>'+
                                          '<div class="mfp-decoration"></div>'+
                                      '</div>'+
                                  '</div>'+
                              '</figcaption>'+
                          '</footer>'+
                      '</figure>'+
                  '</div>',
              }
    });
  };

  this.bindMasonary = function () {
    $('img').load(function () {
      $(".grid").masonry();
    });
    $('.grid').masonry({
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      gutter: 5,
    });
  };

  this.googleMapsApi = function () {
    $('[data-google-map]').each(function () {
      var $mapElement = $(this);
      console.log('$mapElement', $mapElement);


      var map = $mapElement[0],
        mapZoom = 8,
        lats = -26.010754,
        lngs = 28.127379,
        links = true,
        t = [],
        x = [],
        y = [],
        h = [];
      var bounds = new google.maps.LatLngBounds();


      var map_options = {
        center: {lat: lats, lng: lngs},
        zoom: mapZoom,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: true,
        draggable: true
      };

      var googleMap = new google.maps.Map($mapElement[0], map_options);

      console.log(google.maps);


      var mapMarkers = $(map).data('map-markers');

      var map_marker = new google.maps.Marker($mapElement[0], {
        icon: 'fa fa-bell-o'
      });

      var info_window = new google.maps.InfoWindow({
        content: 'loading'
      });

      $.each(mapMarkers, function (i, mapMarker) {
        var latLng = new google.maps.LatLng(mapMarker.lat, mapMarker.lng);
        bounds.extend(latLng);

        var m = new google.maps.Marker({
          map: googleMap,
          animation: google.maps.Animation.DROP,
          position: latLng
        });

        var info_window = new google.maps.InfoWindow({
          content: '<a href="' + mapMarker.url + '" class="map-link">' + mapMarker.title + '</a>'
        });

        google.maps.event.addListener(m, 'click', function () {
          info_window.open(googleMap, m);
        });

        googleMap.fitBounds(bounds);

      });

    });
  };

  this.bindSubmittingButtons = function () {
    $(document).on('submit', function () {
      var submitText = $(this).find('[data-submit-text]');
      var submittingText = $(this).find('[data-submitting-text]');
      var btn = submitText.closest('button');
      submittingText.removeClass('hide');
      submitText.hide();
      btn.prop('disabled', true);
    });
  };

}

function Notifications () {
  var wrapper = $('#notifications-wrapper');
  var div = $('#notifications');
  var wrapperTop = wrapper.offset().top;

  this.bindOnScroll = function () {
    wrapper.height(wrapper.height());
    $(window).on('scroll', function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > wrapperTop) {
        div.addClass('fixed');
      } else {
        div.removeClass('fixed');
      }
    })
  };

  this.bindCloseButton = function () {
    $(document).on('click', '[data-toggle="remove"]', function (e) {
      e.preventDefault();
      var notification = $(this).closest('.notification');
      notification.fadeOut(250, function () {
        wrapper.height('auto');
        wrapper.height(wrapper.height());

      });
    });
  };

  this.init = function () {
    this.bindOnScroll();
    this.bindCloseButton();
  };
}

$(function () {

  (new StickyFooter($('#container'), $('#footer'))).update().bindOnResize();

  (new ExternalLinkHandler).addTargetAttribute($('body'));

  (new PageTimer).logToConsole();

  (new UIBindings).bindTooltips();

  (new UIBindings).bindPopovers();

  (new UIBindings).closeModal();

  (new UIBindings).bindSlickCarousels();

  (new UIBindings).bindSharing();

  (new UIBindings).bindMasonary();

  (new UIBindings).bindMagnificpopup();

  (new UIBindings).bindSubmittingButtons();

  (new UIBindings).googleMapsApi();

  (new Notifications).init();
});